import React, { useState } from "react";
import "./landingPage.css"
import logo from "../../images/Logo.png"
import locationIcon from "../../images/star-location-icon.png"

export default function LandingPage () {

    const [email, setEmail] = useState("");

    const handleChange = (event) => {
        setEmail(event.target.value);
    }


    return (
        <section className="page">
            <section id="hero">

                <nav>
                    <img src={logo} alt="Daydreamt logo" />
                    <button className="pink-background white">Get Early Access</button>
                </nav>

                <article>
                    <h1 className="pink">Daydreamt</h1>
                    <h2 className="white">Unique experiences hosted by locals worldwide</h2>
                    <button className="blue-background white">Get early access</button>
                    <p className="white">Daydreamt is in private beta testing. Register your interest to get early access before we launch to the public!</p>
                </article>

            </section>

            <section id="unique-experiences">
            
                <article className="text-content">

                    <h2 className="pink">Every experience, perfectly unique</h2>

                    <p className="blue">Every Daydreamt experience has something that makes it unique from the others</p>
                    
                    <p className="blue">Fun, relaxing, adventurous, intellectual, immersive, historical and more... find activities of all kinds, each with their own unique element</p>
                    
                    <form className="cta-form">
                        <input type={"text"} placeholder={"Enter email address..."} value={email} onChange={handleChange} className="blue" />
                        <button type="submit" className="blue-background white">Get early access</button>
                    
                    </form>
                    
                    <p className="small blue">Daydreamt is in private beta testing. Register your interest to get early access before we launch to the public!</p>
                
                </article>

                <article id="image-collage">

                    <article className="collage-item" id="collage-castles">
                        <div className="collage-up">
                            <h4 className="white">Royal paths</h4>
                            <p className="white">Hop from castle to castle, travelling paths taken by nobility of old</p>
                        </div>
                        <div className="collage-location">
                            <p className="white">Val de Loire, Fr</p>
                            <img src={locationIcon} alt={"location icon"} />
                        </div>
                    </article>

                    <article className="collage-item" id="collage-speakeasy">
                        <div className="collage-up">
                            <h4 className="white">Secret speakeasy bar crawl</h4>
                            <p className="white">Crawl hidden speakeasy gems even the locals don’t know about</p>
                        </div>
                        <div className="collage-location">
                            <p className="white">London, UK</p>
                            <img src={locationIcon} alt={"location icon"} />
                        </div>
                    </article>

                    <article className="collage-item" id="collage-kolkata">
                        <div className="collage-up">
                            <h4 className="white">Gems of Kolkata</h4>
                            <p className="white">Locals’ favourites </p>
                        </div>
                        <div className="collage-location">
                            <p className="white">Kolkata, India</p>
                            <img src={locationIcon} alt={"location icon"} />
                        </div>
                    </article>

                    <article className="collage-item" id="collage-haunted-tours">
                        <div className="collage-up">
                            <h4 className="white">Haunted tales tour</h4>
                            <p className="white">Visit places famous amongs locals for being haunted</p>
                        </div>
                        <div className="collage-location">
                            <p className="white">Lake District, UK</p>
                            <img src={locationIcon} alt={"location icon"} />
                        </div>
                    </article>

                    <article className="collage-item" id="collage-hieroglyphics">
                        <div className="collage-up">
                            <h4 className="white">Learn hieroglyphics</h4>
                            <p className="white">Learn the basics of interpreting hieroglyphics then use your new skills at nearby tombs</p>
                        </div>
                        <div className="collage-location">
                            <p className="white">Luxor, Egypt</p>
                            <img src={locationIcon} alt={"location icon"} />
                        </div>
                    </article>

                    <article className="collage-item" id="collage-victoria-falls">
                        <div className="collage-up">
                            <h4 className="white">Victoria Falls Yoga</h4>
                            <p className="white">In Victoria Falls National Park</p>
                        </div>
                        <div className="collage-location">
                            <p className="white">Zimbabwe</p>
                            <img src={locationIcon} alt={"location icon"} />
                        </div>
                    </article>
                </article>
            
            </section>

            <section id="lifestyle-holidays">
                <article id="cards">

                    <article className="card" id="fire-bending-card">
                        <div className="card-image" id="fire-image">
                            <div className="card-location">
                                <img src={locationIcon} alt={"location icon"} />
                                <p className="white">London, UK</p>
                            </div>
                        </div>
                        <div className="card-text pink-background">
                            <h5 className="white">Fire performance</h5>
                            <p className="white">Lesson and show on the thames riverside</p>
                        </div>
                    </article>

                    <article className="card" id="waterfalls-card">
                        <div className="card-image" id="waterfalls-image">
                            <div className="card-location">
                                <img src={locationIcon} alt={"location icon"} />
                                <p className="white">Multiple locations, Mauritius</p>
                            </div>
                        </div>
                        <div className="card-text pink-background">
                            <h5 className="white">Waterfalls of Mauritius</h5>
                            <p className="white">Visit and swim in the beloved and mostly unknown waterfalls of Mauritius</p>
                        </div>
                    </article>

                    <article className="card" id="food-card">
                        <div className="card-image" id="food-image">
                            <div className="card-location">
                                <img src={locationIcon} alt={"location icon"} />
                                <p className="white">Buenos Aires, Argentina</p>
                            </div>
                        </div>
                        <div className="card-text pink-background">
                            <h5 className="white">Hidden gems of Buenos Aires</h5>
                            <p className="white">Cycle Buenos Aires visiting hidden gems most tourists (and locals) never see</p>
                        </div>
                    </article>

                    <article className="card" id="dance-card">
                        <div className="card-image" id="dance-image">
                            <div className="card-location">
                                <img src={locationIcon} alt={"location icon"} />
                                <p className="white">Paris, France</p>
                            </div>
                        </div>
                        <div className="card-text pink-background">
                            <h5 className="white">Historical balls</h5>
                            <p className="white">Visit and take part in the historical balls that still take place in Paris</p>
                        </div>
                    </article>

                </article>

                <article className="text-content">

                    <h2 className="pink">Exciting lifestyle, enchanting holidays</h2>

                    <p className="blue">Looking for out of the ordinary things to do? Something that matches your style? Find the perfect experiences for you locally and on holiday</p>

                    <form className="cta-form">
                        <input type={"text"} placeholder={"Enter email address..."} value={email} onChange={handleChange} className="blue" />
                        <button type="submit" className="blue-background white">Get early access</button>

                    </form>

                    <p className="small blue">Daydreamt is in private beta testing. Register your interest to get early access before we launch to the public!</p>

                </article>

            </section>

            <section id="quality" className="pink-background">
                <h2 className="white">Constantly vetted for quality and distinctiveness</h2>
                <p className="blue">Daydreamt is built around communication - with customers and local hosts to ensure the best experiences for everyone</p>
                <p className="blue">Only the most unique and exciting experiences for all tastes and interests</p>
            </section>

            <section id="get-early-access">
                <h2 className="pink">Get Early Access</h2>
                <p className="blue">Daydreamt is currently in private beta testing. Register your interest to get early access before we launch to the public!</p>
                <form className="cta-form">
                        <input type={"text"} placeholder={"Enter email address..."} value={email} onChange={handleChange} className="blue" />
                        <button type="submit" className="blue-background white">Get early access</button>

                </form>
                <p className="blue">Get early access to unique experiences hosted by locals worldwide</p>
            </section>

            <footer className="pink-background">
                <p className="small white">&#169; 2022 Daydreamt</p>
            </footer>

        </section>
    )
}